import React, { useRef, useEffect, useState } from 'react';
import InputField from '../input';
import { FaCaretDown, FaX } from 'react-icons/fa6';
import moment from 'moment';
import Button from '../button';
import { countries } from '../../utils/countries';
import { inquiry } from '../../utils/api';
import { toast } from 'sonner';
import { Drawer, Modal } from 'antd';

const ContactForm = ({ useDrawer, isOpen, onClose, property }) => {
	const ref = useRef(null);
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [contactMode, setContactMode] = useState('phone');
	const [preferredContactTime, setPreferredContactTime] = useState(moment());
	const [selectedCountryPrefix, setSelectedCountryPrefix] = useState('234');
	const [openDropDown, setOpenDropDown] = useState(false);
	// const [openModal, setOpenModal] = useState(false);

	const handlePreferredContactTimeChange = (dateTime) => {
		setPreferredContactTime(dateTime);
	};

	const handleCountryChange = (prefix) => {
		setSelectedCountryPrefix(prefix);
	};

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setOpenDropDown(false);
		}
	};

	const formatPhoneNumber = (phone) => {
		// Check if phone starts with '0'
		if (phone.startsWith('0')) {
			return '+' + selectedCountryPrefix + phone.slice(1);
		}
		// If phone doesn't start with '0', return it as is
		return '+' + selectedCountryPrefix + phone;
	};
	const handleOk = () => {
		onClose();
	};
	const handleCancel = () => {
		onClose();
	};

	const formattedPhone = formatPhoneNumber(phone);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// const propertyId = null;
		try {
			setLoading(true);
			console.log(
				name,
				email,
				formattedPhone,
				message,
				preferredContactTime,
				property?._id,
			);
			const response = await inquiry({
				name,
				email: email !== '' && email,
				phone: phone !== '' && formattedPhone,
				message,
				preferredContactTime,
				propertyId: property?._id,
			});
			console.log(response);
			if (response.status === 200) {
				setName('');
				setEmail('');
				setPhone('');
				setMessage('');
				onClose();
				setPreferredContactTime(moment());
				toast.success('Information Received');
				setLoading(false);
			}
		} catch (error) {
			console.error('Error creating property:', error);
			toast.error(error.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const formDetails = () => {
		return (
			<form
				onSubmit={handleSubmit}
				className='mt-6 space-y-4'>
				<InputField
					block={true}
					label={'Full name'}
					type='text'
					value={name}
					placeholder='Full Name'
					onChange={(e) => setName(e.target.value)}
				/>
				<div className='space-y-2'>
					<div className='text-text-inactive'>
						Preferred mode of contact
					</div>
					<div className='flex space-x-3 bg-gray-100 p-1 mb-3'>
						<div
							onClick={() => setContactMode('phone')}
							className={`w-full space-x-2 border ${
								contactMode === 'phone'
									? 'border-primary-5 bg-primary-base text-white'
									: 'border-gray-100 text-text-inactive'
							} px-2.5 py-1 flex justify-center rounded-lg`}>
							<p>WhatsApp Number</p>
						</div>
						<div
							onClick={() => setContactMode('email')}
							className={`w-full space-x-2 border ${
								contactMode === 'email'
									? 'border-primary-5 bg-primary-base text-white'
									: 'border-gray-100 text-text-inactive'
							} px-2.5 py-1 flex justify-center rounded-lg`}>
							<p>Email Address</p>
						</div>
					</div>

					{contactMode === 'phone' && (
						<div className='flex space-x-2 w-full'>
							<div
								className='relative text-base mb-2  text-left duration-300 ease-in-out lg:mb-0 '
								ref={ref}>
								<div
									className='h-[52px] w-[96px] truncate border items-center flex space-x-2 rounded-lg px-2 cursor-pointer'
									onClick={() => {
										setOpenDropDown(!openDropDown);
									}}>
									<p>+ {selectedCountryPrefix}</p>{' '}
									<FaCaretDown className='-mt-1' />
								</div>
								{openDropDown && (
									<div className='absolute z-20 py-4 mt-2 bg-white rounded-md overflow-visible text-left flex flex-col items-start shadow-lg border h-[25vh] overflow-y-scroll'>
										{countries.map((country) => (
											<div
												key={country.code}
												className='relative w-full'>
												<p
													onClick={() => {
														handleCountryChange(
															country.prefix,
														);
														setOpenDropDown(false);
													}}
													className='whitespace-nowrap z-20 w-full px-4 py-2 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900'>
													{country.name} (+
													{country.prefix})
												</p>
											</div>
										))}
									</div>
								)}
							</div>

							<div className='w-full'>
								<InputField
									block={true}
									label={'WhatsApp Number'}
									type='text'
									value={phone}
									placeholder='WhatsApp Number'
									onChange={(e) => setPhone(e.target.value)}
								/>
							</div>
						</div>
					)}

					{contactMode === 'email' && (
						<InputField
							block={true}
							label={'Email Address'}
							type='text'
							value={email}
							placeholder='Email Address'
							onChange={(e) => setEmail(e.target.value)}
						/>
					)}
				</div>

				<div className='flex-col flex space-y-2'>
					<label
						htmlFor='preferredContactTime'
						className='text-text-inactive'>
						Preferred Contact Time
					</label>
					<InputField
						block={true}
						label={'Message'}
						type='datetime-local'
						value={preferredContactTime.format('YYYY-MM-DDTHH:mm')}
						onChange={(e) =>
							handlePreferredContactTimeChange(
								moment(e.target.value),
							)
						}
					/>
				</div>

				<InputField
					block={true}
					label={'Message'}
					type='textarea'
					value={message}
					placeholder='Enter a message'
					onChange={(e) => setMessage(e.target.value)}
				/>

				<Button
					block={true}
					size='large'
					variant='primary'
					type='submit'
					loading={loading}
					loadingText={'Sending details...'}
					active={
						name ||
						phone ||
						email ||
						preferredContactTime ||
						message
					}>
					Drop us a line
				</Button>
			</form>
		);
	};

	return (
		<>
			{!useDrawer && isOpen && (
				<Modal
					className='rounded-t-xl'
					height='90%'
					open={isOpen}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={false}
					closable={true}
					title={
						<>
							<div className='flex justify-between'>
								<div className='text-[24px]'>
									Drop us a line
								</div>
								<div
									className='h-8 w-8 flex items-center justify-center bg-gray-100 rounded-full'
									onClick={onClose}>
									<FaX size={13} />
								</div>
							</div>
						</>
					}>
					<div>
						<p className='w-[75%]'>
							Leave us your details and we will get in touch with
							you super fast ⚡
						</p>
						{formDetails()}
					</div>
				</Modal>
			)}

			{useDrawer && isOpen && (
				<Drawer
					title={
						<div className='flex justify-between'>
							<div className='text-[24px]'>Drop us a line</div>
							<div
								className='h-8 w-8 flex items-center justify-center bg-gray-100 rounded-full'
								onClick={onClose}>
								<FaX size={13} />
							</div>
						</div>
					}
					placement='bottom'
					closable={false}
					onClose={handleCancel}
					open={isOpen}
					// key="bottom"
					className='rounded-t-xl p-4'
					height='75vh'
					bodyStyle={{ padding: '0', paddingTop: '12px' }}
					headerStyle={{
						padding: '0',
						paddingTop: '8px',
						paddingBottom: '12px',
					}}>
					<div>
						<p className='w-[75%]'>
							Leave us your details and we will get in touch with
							you super fast ⚡
						</p>
						{formDetails()}
					</div>
				</Drawer>
			)}
		</>
	);
};

export default ContactForm;
