import React, { Suspense, lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AboutUs from './pages/about';

// Use lazy loading
const LandingPage = lazy(() => import('./pages/main'));
const Properties = lazy(() => import('./pages/properties/listings'));
const CreatePropertyPage = lazy(() => import('./pages/admin/overview'));
const PropertyDetails = lazy(() => import('./pages/properties/details'));
const Page404 = lazy(() => import('./404'));

const LoadingIndicator = () => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			// Increment progress up to 95%
			if (progress < 95) {
				setProgress((prevProgress) => prevProgress + 5);
			}
		}, 400);

		return () => {
			clearInterval(interval);
		};
	}, [progress]);

	return (
		<div className='flex flex-col w-full h-screen items-center justify-center'>
			<div className='w-[25%] mx-auto'>
				<p className='mb-2'>Loading...</p>
				<div className='h-1 bg-gray-200 shadow'>
					<div
						className='h-full bg-blue-700 ease-in-out'
						style={{
							width: `${progress}%`,
							transition: 'width 0.2s ease-in-out',
						}}></div>
				</div>
			</div>
		</div>
	);
};

const Root = () => {
	const [useDrawer, setUseDrawer] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		if (screenWidth < 768) {
			setUseDrawer(true);
		} else {
			setUseDrawer(false);
		}

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [screenWidth]);

	return (
		<BrowserRouter basename='/'>
			<Suspense
				fallback={
					<div>
						<LoadingIndicator />
					</div>
				}>
				<Routes>
					<Route
						path='/'
						element={<LandingPage useDrawer={useDrawer} />}
					/>
					<Route
						path='/properties'
						element={<Properties useDrawer={useDrawer} />}
					/>
					<Route
						path='/about'
						element={<AboutUs useDrawer={useDrawer} />}
					/>
					<Route
						path='/admin'
						element={<CreatePropertyPage useDrawer={useDrawer} />}
					/>

					<Route
						path='/properties/:propertyId'
						element={<PropertyDetails useDrawer={useDrawer} />}
					/>
					<Route
						path='*'
						element={<Page404 useDrawer={useDrawer} />}
					/>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
