import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
	const navigate = useNavigate();
	return (
		<footer className='border-t bg-white'>
			<div className='md:max-w-[1440px] md:mx-auto py-10 px-2'>
				<div className='md:flex justify-between space-y-8 md:space-y-0'>
					<div className='md:flex space-y-12 md:space-y-0 md:space-x-24'>
						<div
							className=''
							onClick={() => navigate('/')}>
							{/* Logo */}

							<img
								loading='lazy'
								src='https://res.cloudinary.com/dh3ecxwfk/image/upload/f_webp/noaljdm98rpk8lcfo6yn.jpg'
								alt=''
								className='h-[24px] w-auto'
								width='auto'
								height='24'
							/>
						</div>
						<div className='space-y-6 md:space-y-0'>
							<p className='font-semibold mb-2 text-text-active'>
								Company
							</p>
							<ul className='md:flex space-y-4 md:space-y-0 md:space-x-8 text-text-inactive'>
								<li onClick={() => navigate('/properties')}>
									Properties
								</li>
								<li onClick={() => navigate('/about')}>
									About us
								</li>
								{/* <li onClick={() => navigate('/properties')}>
									Contact Us</a>
								</li> */}
								<li>FAQ</li>
							</ul>
						</div>
					</div>
					<div className='space-y-6 md:space-y-0'>
						<p className='font-semibold mb-2 text-text-active'>
							Contact Us
						</p>
						<ul className='md:flex space-y-4 md:space-y-0 md:space-x-8 text-text-inactive'>
							<li>
								<a href='mailto:hello@propslot.com'>
									hello@propslot.com
								</a>
							</li>
							<li>
								<a href='https://www.twitter.com/propslot'>
									Twitter
								</a>
							</li>
							<li>
								<a href='https://www.instagram.com/propslot'>
									Instagram
								</a>
							</li>
							<li>
								<a href='https://www.linkedin.com/company/propslot'>
									Linkedin
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
