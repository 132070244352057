import React from 'react';

const Button = ({
	variant,
	onClick,
	children,
	iconPosition = 'left',
	icon,
	loading,
	loadingText,
	size = 'medium', // Default size is medium
	block = false, // Default is false (does not fill width)
	active = true, // Default is true (button is active)
}) => {
	const handleClick = () => {
		if (!loading && onClick && active) {
			onClick();
		}
	};

	// Define padding classes based on size
	let paddingClasses = 'px-4 py-2.5'; // Medium size by default
	if (size === 'small') {
		paddingClasses = 'px-3 py-1';
	} else if (size === 'large') {
		paddingClasses = 'px-6 py-4';
	}

	// Define width classes based on block prop
	const widthClasses = block ? 'w-full' : 'w-fit';

	return (
		<button
			onClick={handleClick}
			className={`font-medium flex items-center justify-center rounded-xl whitespace-nowrap ${
				variant === 'primary' && active
					? 'bg-primary-base text-white' : variant !== 'primary' && active
					? 'border border-primary-base/40 text-primary-base' : "bg-gray-200 text-gray-400"
			} ${paddingClasses} ${widthClasses}`}
			disabled={!active || loading}
			>
			{iconPosition === 'left' && icon && (
				<span className='mr-2'>{icon}</span>
			)}
			<p className='m-0'>{loading ? loadingText : children}</p>
			{iconPosition === 'right' && icon && (
				<span className='ml-2'>{icon}</span>
			)}
		</button>
	);
};

export default Button;
