import axios from 'axios';

const testenvironment = process.env.REACT_APP_ENV;
const baseURL =
	testenvironment === 'test'
		? process.env.REACT_APP_LOCALHOST_API
		: process.env.REACT_APP_PRODUCTION_API;

const api = axios.create({
	baseURL,
});

export const inquiry = async (data) => {
	try {
		console.log(data);
		const response = await api.post('/inquiry', data);
		return response;
	} catch (error) {
		console.error(error.message);
	}
};

export const search = async (keyword) => {
	try {
		const response = await api.get('/search/properties', {
			params: { keyword },
		});
		console.log(response);
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const joinWaitlist = async (email) => {
	try {
		const response = await api.post('/waitlist', email);
		console.log(response);
		return response;
	} catch (error) {
		console.error(error.message);
	}
};

export const getProperties = async () => {
	try {
		const response = await api.get('/properties');
		console.log(response)
		return response;
	} catch (error) {
		console.error(error.message);
	}
};

export const createProperty = async (propertyData) => {
	try {
		console.log(propertyData);
		const response = await api.post('/properties', propertyData);
		console.log('Create property response: ', response);
		return response;
	} catch (error) {
		console.error(error.message);
	}
};

export const getPropertyById = async (id) => {
	try {
		const response = await api.get(`/properties/${id}`);
		return response.data;
	} catch (error) {
		console.error(error.message);
	}
};

export const updateProperty = async (id, propertyData) => {
	try {
		console.log(id, propertyData);
		const response = await api.put(`/properties/${id}`, propertyData);
		console.log(response);
		return response;
	} catch (error) {
		console.error(error.message);
	}
};

export const deleteProperty = async (id) => {
	try {
		const response = await api.delete(`/properties/${id}`);
		console.log(response);
		return response;
	} catch (error) {
		console.error(error.message);
	}
};
