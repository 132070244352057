import React, { useState } from 'react';
import Layout from '../layout';
import Footer from '../components/footer';
import { ReactComponent as Hero } from '../assets/Img2.svg';
import Button from '../components/button';
import ContactForm from '../components/forms/contact-us';

const AboutUs = ({ useDrawer }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Layout>
			<div className='min-h-[85vh]'>
				<section className='h-[20rem] md:h-[45vh] mt-16 md:mt-24 rounded-xl md:max-w-[1440px] mx-auto bg-primary-base text-white relative overflow-hidden'>
					<div className='absolute text-primary-5/20 md:right-0 rotate-[180deg] -z-5'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 46.43 45.56'
							className='h-[40rem] w-auto'
							fill='none'
							stroke='none'>
							<path
								className='fill-current'
								d='M.45,0h-.45V4.56H.45c22.7,.03,41.16,18.3,41.42,41h4.56C46.19,20.33,25.68,0,.45,0Z'
							/>
							<path
								className='fill-current'
								d='M.45,9.11h-.45v4.55H.45c17.67,.02,32.05,14.21,32.32,31.87h4.55C37.05,25.35,20.63,9.13,.45,9.11Z'
							/>
							<path
								className='fill-current'
								d='M.45,19.12h-.45v4.56H.45c12.14,.01,22.04,9.72,22.3,21.85h4.55c-.26-14.65-12.2-26.39-26.85-26.41Z'
							/>
							<path
								className='fill-current'
								d='M.45,28.23h-.45v4.6H.45c7.09,.01,12.91,5.62,13.18,12.7h4.56c-.25-9.62-8.12-17.29-17.74-17.3Z'
							/>
							<path
								className='fill-current'
								d='M.45,37.33h-.45v8.2H9.13c-.24-4.61-4.06-8.23-8.68-8.2Z'
							/>
						</svg>
					</div>
					<div className='md:m-12 h-full w-full relative'>
						<p className=' text-[48px] font-medium m-8 md:m-0'>
							About us
						</p>
						<Hero className='absolute w-80 md:w-auto -left-8 -bottom-24 md:-bottom-8 md:-left-6 z-10' />
						<Hero className='absolute w-80 md:w-auto -left-4 -bottom-16 md:-bottom-4 md:-left-2 z-10' />
					</div>
				</section>
				<section className='md:px-12 py-8 md:py-20 space-y-2 text-text-active md:flex w-full items-center border-b md:max-w-[1440px] mx-auto'>
					<div className='space-y-4 md:space-y-0 md:space-x-8 md:flex items-center justify-center w-full'>
						<p className=' font-semibold text-[32px] leading-[115%] w-full md:w-[35.5%]'>
							Providing you the opportunity to own real estate and
							build wealth
						</p>
						<p className='text-text-inactive w-full md:w-1/3'>
							Our ambition is to reduce the barrier to entry into
							the real estate market for Africans to easily access
							high-value properties, earn income and build wealth
							for themselves.
						</p>
					</div>
				</section>
				<section className='md:px-12 py-8 border-b md:py-20 space-y-2 text-text-active md:flex w-full items-center md:max-w-[1440px] mx-auto'>
					<div className='space-y-4 md:space-y-0 md:space-x-8 md:flex items-center justify-center w-full'>
						<p className=' font-semibold text-[32px] leading-[115%] w-full md:w-[35.5%]'>
							Propslot's Mission
						</p>
						<p className='text-text-inactive w-full md:w-1/3'>
							Real estate ownership should not be as tedious,
							expensive or complicated as it is now. We are making
							it simple, accessible and transparent for you to
							enjoy the benefits of being a landlord without the
							stress.
						</p>
					</div>
				</section>
				<section className='md:px-12 py-8 md:py-20 space-y-2 text-text-active md:flex w-full items-center md:max-w-[1440px] mx-auto'>
					<div className='space-y-4 md:space-y-0 md:space-x-8 md:flex items-center justify-center w-full'>
						<p className=' font-semibold text-[32px] leading-[115%] w-full md:w-[35.5%]'>
							How we do it
						</p>
						<p className='text-text-inactive w-full md:w-1/3'>
							After rigorously vetting the desired property, we
							turn the property into property slots and make it
							available for you to co-own. Property Slot Experts
							then manage the property, and tenants, ensuring your
							property is of benefit to you.
						</p>
					</div>
				</section>
				<section className='z-10 mb-32 mt-8'>
					<div className='md:max-w-[1440px] md:mx-auto flex py-[5rem] text-center md:text-left justify-center md:grid md:grid-cols-2 px-[16px] md:px-[120px] items-center bg-[#f5f5ff] rounded-xl overflow-hidden relative'>
						<div className='space-y-12 z-10'>
							<div className='space-y-2 '>
								<p className='text-[32px] font-semibold md:w-[75%] leading-tight'>
									Got Questions?
								</p>
								<p className='md:w-[75%] leading-tight'>
									Drop us a line and we'll get right to it
								</p>
							</div>
							<div className='z-10 md:flex mt-[24px] space-y-4 md:space-y-0 md:space-x-4 md:w-[50%]'>
								<Button
									size='large'
									block={true}
									variant='primary'
									onClick={() => {
										setIsOpen(true);
									}}>
									Contact Us
								</Button>
							</div>
						</div>
						<div className='absolute text-primary-5/30 top-0 right-0 rotate-180'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 46.43 45.56'
								className='h-[40rem] w-auto'
								fill='none'
								stroke='none'>
								<path
									className='fill-current'
									d='M.45,0h-.45V4.56H.45c22.7,.03,41.16,18.3,41.42,41h4.56C46.19,20.33,25.68,0,.45,0Z'
								/>
								<path
									className='fill-current'
									d='M.45,9.11h-.45v4.55H.45c17.67,.02,32.05,14.21,32.32,31.87h4.55C37.05,25.35,20.63,9.13,.45,9.11Z'
								/>
								<path
									className='fill-current'
									d='M.45,19.12h-.45v4.56H.45c12.14,.01,22.04,9.72,22.3,21.85h4.55c-.26-14.65-12.2-26.39-26.85-26.41Z'
								/>
								<path
									className='fill-current'
									d='M.45,28.23h-.45v4.6H.45c7.09,.01,12.91,5.62,13.18,12.7h4.56c-.25-9.62-8.12-17.29-17.74-17.3Z'
								/>
								<path
									className='fill-current'
									d='M.45,37.33h-.45v8.2H9.13c-.24-4.61-4.06-8.23-8.68-8.2Z'
								/>
							</svg>
						</div>
					</div>
				</section>
			</div>
			<ContactForm
				useDrawer={useDrawer}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
			<Footer />
		</Layout>
	);
};

export default AboutUs;
