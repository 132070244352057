import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaCaretDown, FaChevronDown, FaX } from 'react-icons/fa6';
import Button from './button';
import { useLocation } from 'react-router-dom/dist';
import { search } from '../utils/api';
import { Drawer, Modal } from 'antd';
import animationData from '../assets/Animation - 1714135397366.json';
import Lottie from 'lottie-react';
import InputField from './input';
import { FaSearch } from 'react-icons/fa';
import ContactForm from './forms/contact-us';

const Header = ({ pageTitle }) => {
	// const aboutDropdownList = [
	// 	{
	// 		key: 'About Propslot',
	// 		value: 'propslot',
	// 		onclick: '',
	// 	},
	// 	{
	// 		key: 'FAQs',
	// 		value: 'faq',
	// 		onclick: '',
	// 	},
	// ];

	const navItems = [
		{
			key: 'properties',
			value: 'Properties',
			dropdown: false,
		},
		{
			key: 'about',
			value: 'About Us',
			dropdown: false, //switch to true if using dropdown
			// list: aboutDropdownList,
		},
	];

	const tabs = [
		{ label: 'Properties', field: 'name' },
		{ label: 'Locations', field: 'location' },
		{ label: 'Descriptions', field: 'description' },
	];

	const navigate = useNavigate();
	const location = useLocation();
	const pathname = location.pathname;
	const isPropertiesPage = pathname === '/properties';

	const [isActive, setIsActive] = useState('');
	const [activeDropdown, setActiveDropdown] = useState('');
	const dropdownRef = useRef(null);
	const [keyword, setKeyword] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [activeTab, setActiveTab] = useState('name');
	const [openModal, setOpenModal] = useState(false);
	const [searchInitiated, setSearchInitiated] = useState(false);
	const [useDrawer, setUseDrawer] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [isSideBarOpen, setIsSidebarOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleTabClick = (field) => {
		setActiveTab(field);
	};

	const handleOpenDropDown = (navKey) => {
		if (activeDropdown === navKey) {
			setActiveDropdown('');
			setIsActive(navKey);
		} else {
			setActiveDropdown(navKey);
		}
	};

	const handleOk = () => {
		setOpenModal(false);
	};
	const handleCancel = () => {
		setOpenModal(false);
	};

	const handleSearch = async (e) => {
		try {
			setKeyword(e.target.value);
			console.log(e.target.value);
			const response = await search(e.target.value);
			console.log(response);
			setSearchResults(response.data.results);
			setSearchInitiated(true);
		} catch (error) {
			console.error('Error searching:', error);
		}
	};

	const handleClickOutside = (event) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target)
		) {
			setActiveDropdown('');
		}
	};

	const handleOpenSearchModal = () => {
		setOpenModal(true);
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleNavigate = (navKey) => {
		setIsActive(navKey);
		navigate(`/${navKey}`);
	};

	const searchComponent = () => {
		return (
			<>
				<InputField
					type={'text'}
					block={true}
					value={keyword}
					onChange={(e) => handleSearch(e)}
					placeholder='Enter a keyword'
				/>

				{searchInitiated ? (
					<div className='tabs'>
						<ul className='flex space-x-2 font-semibold mt-4'>
							{tabs.map((tab) => (
								<li
									key={tab.field}
									className={`py-1.5 px-5 ${
										activeTab === tab.field
											? 'text-white bg-primary-base'
											: 'text-text-inactive'
									} rounded-md`}>
									<button
										onClick={() =>
											handleTabClick(tab.field)
										}>
										{tab.label}
									</button>
								</li>
							))}
						</ul>
						<div className='tab-content'>
							<>
								{searchResults[activeTab] &&
								searchResults[activeTab].length > 0 ? (
									<div className='space-y-2'>
										{searchResults[activeTab].map(
											(result, index) => (
												<div
													onClick={() => {
														navigate(
															`/properties/${encodeURI(
																result._id,
															)}`,
														);
													}}
													key={index}
													className='bg-gray-50 p-3 mt-4 cursor-pointer'>
													<p className='font-semibold text-text-active'>
														{result.name}
													</p>
													<p className='text-text-inactive'>
														{result.location}
													</p>
												</div>
											),
										)}
									</div>
								) : (
									<div className='w-[75%] mx-auto flex items-center text-center justify-center h-[40vh]'>
										<div>
											<Lottie
												className='h-48 '
												animationData={animationData}
											/>
											<p>
												No results found with that{' '}
												{activeTab}
											</p>
										</div>
									</div>
									// <p>No results found</p>
								)}
							</>
						</div>
					</div>
				) : (
					<div className='w-[75%] mx-auto flex items-center text-center justify-center h-[40vh]'>
						<div>
							<Lottie
								className='h-48 '
								animationData={animationData}
							/>
							<p>
								Enter a key word to find either a property, a
								location or some other description you're
								looking for
							</p>
						</div>
					</div>
				)}
			</>
		);
	};

	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		if (screenWidth < 768) {
			setUseDrawer(true);
		} else {
			setUseDrawer(false);
		}

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [screenWidth]);

	return (
		<div className='fixed top-0 z-50 w-full'>
			<header className='w-full border-b bg-white h-[72px] md:h-[80px] flex items-center md:px-[20px]'>
				<div
					className={`flex w-full items-center justify-between ${
						isPropertiesPage
							? 'max-w-[2560px] md:mx-auto'
							: 'md:max-w-[1440px] md:mx-auto '
					} mx-[16px] `}>
					<div className='flex space-x-[32px] w-full '>
						<div className='space-x-[8px] flex items-center'>
							<FaBars
								className='text-xl block md:hidden'
								onClick={() => setIsSidebarOpen(true)}
								size={18}
							/>

							<img
								src='https://res.cloudinary.com/dh3ecxwfk/image/upload/f_webp/noaljdm98rpk8lcfo6yn.jpg'
								alt='Propslot Logo'
								className='h-[24px] w-auto cursor-pointer'
								onClick={() => navigate('/')}
								width='auto'
								height='24'
							/>
						</div>

						<ul className='md:flex hidden whitespace-nowrap'>
							{navItems.map((nav) => (
								<li
									key={nav.key}
									className={`${
										isActive === nav.key
											? 'text-primary-base'
											: 'text-text-inactive'
									} relative cursor-pointer py-[4px] px-[16px] flex items-center`}
									onClick={() => {
										nav.dropdown
											? handleOpenDropDown(nav.key)
											: handleNavigate(nav.key);
									}}>
									<div className='flex space-x-[8px] items-center'>
										<div>{nav.value}</div>
										<div className='-mt-1'>
											{nav.dropdown && (
												<FaChevronDown size={14} />
											)}
										</div>
									</div>

									{/* Dropdown render */}
									{activeDropdown === nav.key && (
										<div
											ref={dropdownRef}
											className='absolute top-10 w-fit bg-white border rounded-lg shadow-md'>
											{nav.list.map((dropdown) => {
												return (
													<li
														key={dropdown.key}
														className='py-4 px-5 hover:bg-gray-200 min-w-[240px] '>
														{dropdown.value}
													</li>
												);
											})}
										</div>
									)}
								</li>
							))}
						</ul>
					</div>
					<div
						className='md:hidden bg-primary-5/50 p-3 rounded-lg text-primary-90'
						onClick={handleOpenSearchModal}>
						<FaSearch />
					</div>
					<div className='md:flex items-center space-x-[16px] w-1/3 place-content-end hidden'>
						<input
							type='text'
							// onChange={(e) => handleSearch(e)}
							onClick={handleOpenSearchModal}
							placeholder='Search'
							className='border rounded-xl pl-3 py-2.5'
						/>

						<Button
							size='medium'
							variant={'primary'}
							onClick={() => setIsOpen(true)}>
							Contact us
						</Button>
					</div>
				</div>
			</header>
			{/* Secondary Header */}
			{pageTitle && (
				<div
					className='md:hidden bg-white/80 py-2.5 border-b border-gray-100'
					style={{ backdropFilter: 'blur(24px)' }}>
					<div className='mx-[16px] text-text-inactive'>
						{pageTitle}
					</div>
				</div>
			)}

			{!useDrawer && openModal && (
				<Modal
					className='rounded-t-xl'
					height='90%'
					open={openModal}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={false}
					closable={true}
					title={<div className='text-[24px]'>Search</div>}>
					{searchComponent()}
				</Modal>
			)}
			{useDrawer && openModal && (
				<Drawer
					placement='bottom'
					closable={false}
					onClose={handleCancel}
					open={openModal}
					// key="bottom"
					className='rounded-t-xl p-4'
					height='80vh'
					bodyStyle={{ padding: '0', paddingTop: '12px' }}
					headerStyle={{
						padding: '0',
						paddingTop: '8px',
						paddingBottom: '12px',
					}}>
					{searchComponent()}
				</Drawer>
			)}
			{isSideBarOpen && (
				<>
					<Drawer
						placement='left'
						closable={false}
						open={isSideBarOpen}
						onClose={() => {
							setIsSidebarOpen(false);
						}}
						width={360}
						bodyStyle={{ padding: '24px' }}
						headerStyle={{
							padding: '0',
							paddingTop: '8px',
							paddingBottom: '12px',
						}}>
						<div className='space-y-12'>
							<div className='flex justify-between items-center'>
								<img
									src='https://res.cloudinary.com/dh3ecxwfk/image/upload/f_webp/noaljdm98rpk8lcfo6yn.jpg'
									alt='Propslot Logo'
									className='h-[24px] w-auto cursor-pointer'
									onClick={() => navigate('/')}
									width='auto'
									height='24'
								/>
								<div
									className='p-2'
									onClick={() => {
										setIsSidebarOpen(false);
									}}>
									<FaX size={18}/>
								</div>
							</div>

							<div className='space-y-6'>
								{navItems.map((nav) => (
									<li
										key={nav.key}
										className={`${
											isActive === nav.key
												? 'text-primary-base'
												: 'text-text-inactive'
										} relative cursor-pointer flex items-center`}
										onClick={() => {
											nav.dropdown
												? handleOpenDropDown(nav.key)
												: handleNavigate(nav.key);
										}}>
										<div
											className={`flex justify-between w-full items-center ${
												isActive === nav.key &&
												'bg-primary-5/50'
											}`}>
											<div>{nav.value}</div>
											<div className=''>
												{nav.dropdown && (
													<FaCaretDown size={14} />
												)}
											</div>
										</div>

										{/* Dropdown render */}
										{activeDropdown === nav?.key && (
											<div
												ref={dropdownRef}
												className='absolute top-10 w-fit '>
												{nav.list.map((dropdown) => {
													return (
														<p
															key={dropdown.key}
															className='py-2 px-5 hover:bg-gray-200 min-w-[240px] '>
															{dropdown.value}
														</p>
													);
												})}
											</div>
										)}
									</li>
								))}
							</div>
							<div className='mt-24'>
								<Button
									variant={'primary'}
									onClick={() => {
										setIsSidebarOpen(false);
										setIsOpen(true);
									}}>
									Contact us
								</Button>
							</div>
						</div>
					</Drawer>
				</>
			)}

			<ContactForm
				useDrawer={useDrawer}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</div>
	);
};

export default Header;
