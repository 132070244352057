import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/header';

const Layout = ({ children, title, meta, pageTitle, onContact }) => {
	return (
		<div className='h-screen flex flex-col'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{title || 'Propslot'}</title>
				{meta &&
					meta.map((metaItem) => (
						<meta
							key={metaItem.name}
							name={metaItem.name}
							content={metaItem.content}
						/>
					))}
			</Helmet>
			<header className='flex-shrink-0'>
				<Header
					pageTitle={pageTitle}
					onContact={onContact}
				/>
			</header>
			<main className='flex-grow py-8 relative'>
				<section className='z-20 px-4'>{children}</section>
				<div className='h-full absolute top-0 grid grid-cols-3 md:grid-cols-12 w-full opacity-40 -z-10  md:pl-0'>
					<div className='border-r h-full'></div>
					<div className='border-r h-full'></div>
					<div className='border-r h-full'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
					<div className='border-r h-full hidden md:block'></div>
				</div>
			</main>
		</div>
	);
};

export default Layout;
