import React, { useState } from 'react';

const InputField = ({
	type,
	value,
	placeholder,
	onChange,
	label,
	leftElement,
	block = false, 
	leftIndent
}) => {
	const [focused, setFocused] = useState(false);

	const handleFocus = () => {
		setFocused(true);
	};

	const handleBlur = () => {
		if (!value) {
			setFocused(false);
		}
	};

	const InputComponent = type === 'textarea' ? 'textarea' : 'input';

	// Define width classes based on block prop
	const widthClasses = block ? 'w-full' : 'w-fit';

	return (
		<div
			className={`relative ${
				type !== 'textarea' && 'flex items-center'
			} `}>
			{leftElement && (
				<span className='absolute inset-y-0 left-2 flex items-center'>
					{leftElement}
				</span>
			)}
			<InputComponent
				value={value}
				type={type}
				// placeholder={placeholder}
				onChange={onChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				className={`${
					type === 'textarea' ? 'h-auto min-h-[20vh]' : 'h-[52px]'
				} ${
					leftElement && 'pl-12'
				} border rounded-xl p-2.5 ${widthClasses} outline-none`}
			/>
			<label
				htmlFor={label}
				onClick={() => {
					setFocused(true);
				}}
				className={`absolute transition-all duration-300 ease-in-out ${
					focused || value
						? 'text-primary-base left-4 -top-2.5 text-xs'
						: ' text-base text-text-inactive '
				} ${leftElement ? leftIndent : 'left-4'}`}>
				{placeholder}
			</label>
		</div>
	);
};

export default InputField;
